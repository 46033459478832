
  import { Component } from 'vue-property-decorator'
  import DefaultLayout from '@/layouts/Index.vue'

@Component({
  components: {
    ViewFooter: () => null,
    Drawer: () => null,
  },
})
  export default class Index extends DefaultLayout { }
